<template>
    <div class="login">
        <img class="logo" style="cursor: pointer" @click="toHome" src="../assets/logo.png" alt="" />
        <div class="loginBox">
            <div class="title mBotton">云格数字后台管理系统</div>
            <div class="Line mBotton"></div>
            <div class="title mBotton" style="margin-bottom: 50px">登录</div>
            <el-form class="form" :model="loginForm" status-icon :rules="rules" ref="loginForm" label-width="0">
                <el-form-item label="" prop="accountId">
                    <el-input v-model="loginForm.accountId" placeholder="请输入管理员账号">
                        <i slot="prefix" style="color: #4e93fd" class="el-icon-user"></i>
                    </el-input>
                </el-form-item>
                <el-form-item label="" prop="password">
                    <el-input type="password" v-model="loginForm.password" placeholder="请输入登录密码">
                        <i slot="prefix" style="color: #4e93fd" class="el-icon-lock"></i>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <div class="submit" style="margin-top: 20px" @click="submit">登录</div>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
import { handlerLogin } from '@/request/api.js' // 导入api接口

export default {
    components: {},
    data() {
        return {
            loginForm: { accountId: '', password: '' },
            rules: {
                accountId: [{ required: true, trigger: 'blur', message: '账号不能为空' }],
                password: [{ required: true, trigger: 'blur', message: '密码不能为空' }],
            },
        }
    },
    created() {},
    mounted() {},
    methods: {
        toHome() {
            this.$router.push({ path: '/index/home' })
        },
        submit() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    handlerLogin(this.loginForm).then((res) => {
                        if (res.code == '200') {
                            this.$message.success(res.msg)
                            sessionStorage.setItem('userInfo', JSON.stringify(this.loginForm))
                            this.$router.push({ path: '/index/articleManage' })
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }
            })
        },
    },
    watch: {},
}
</script>

<style lang="less" scoped>
.login {
    position: relative;
    padding: 12px 85px;
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: 100% 100%;
    background: url('../assets/image/loginbeijing.png') no-repeat;
    .login {
        width: 190px;
        height: 80px;
    }
    .loginBox {
        position: absolute;
        top: 220px;
        left: 300px;
        width: 350px;
        .mBotton {
            margin-bottom: 24px;
        }
        .title {
            color: #333333;
            font-weight: bold;
            font-size: 30px;
        }
        .Line {
            width: 65px;
            height: 4px;
            background: #5baff1;
        }
        .form {
            /deep/.el-input {
                margin-top: 20px;
            }
            /deep/ .el-input__inner {
                border: 0;
                border-radius: 0px;
                border-bottom: 1px solid #e1e1e1;
                background-size: 100% 100%;
            }
            .submit {
                width: 200px;
                height: 40px;
                background: #4b75f6;
                opacity: 0.9;
                border-radius: 4px;
                font-size: 20px;
                color: #ffffff;
                line-height: 40px;
                text-align: center;
                cursor: pointer;
            }
        }
    }
}
</style>
