import { get, post } from './http'

// 请求轮播图
export function getSwiperByState(params) {
    return get('/swiper/getSwiperByState', params)
}

// 验证是否注册过账号
export function isRegister(params) {
    return get('https://test.fzygsz.com:7001/main/isRegister', params)
}

// 获取手机验证码
export function getPhoneCode1(params) {
    return get('https://test.fzygsz.com:7001/phone/getPhoneCode1', params)
}

// 立即申请
export function applyRegister(params) {
    return post('https://test.fzygsz.com:7001/main/register', params)
}

// 获取首页信息
export function getThreeInfo() {
    return get('information/getThreeInfo')
}

// 管理员登录API
export function handlerLogin(params) {
    return post('/website/handlerLogin', params)
}
